import {goto} from '$app/navigation';
import {type BeforeNavigate, type NavigationTarget} from '@sveltejs/kit';
import {get} from 'svelte/store';
import {page} from '$app/stores';
import {type RouteDefinition} from './routesDefinition';
import {REDIRECT_TO_SEARCH_PARAM} from './searchParams';
import {parseWlkLink} from '../wellKnown';
import {resolveRoute} from '$app/paths';
import {routesDefinitionStore} from '$stores/navigation';

export type RouteParams = {
	itemId?: string;
	type?: string;
	itemName?: string;
};

export function getRoutePath(
	routeName: string,
	routeParams: RouteParams = {},
	searchParams: Record<string, string> | undefined = undefined
): string {
	const routeDef = getRouteDefinition(routeName);
	if (routeDef) {
		const pathname = routeDef.pathname;
		let resolvedRoute = resolveRoute(pathname, routeParams);
		const urlSearchParams = new URLSearchParams();
		for (const key in searchParams) {
			urlSearchParams.set(key, searchParams[key]);
		}
		if (urlSearchParams.size) {
			resolvedRoute = `${resolvedRoute}?${urlSearchParams.toString()}`;
		}

		return resolvedRoute;
	}

	return '';
}

export function getRouteDefinition(
	routeName: string
): RouteDefinition | undefined {
	return get(routesDefinitionStore).find(
		(r) => r.pattern.test(routeName) || r.name === routeName
	);
}

export function wellKnownHook(navigation: BeforeNavigate) {
	const to = navigation.to as NavigationTarget;

	if (to?.url?.pathname.includes('wk/')) {
		navigation.cancel();

		const wkLink = to.url.pathname;
		return parseWlkLink(wkLink);
	}
}

export function getCurrentRouteName(
	routeName: string
): RouteDefinition['name'] | undefined {
	return getRouteDefinition(routeName)?.name;
}

export async function redirectToLogin(): Promise<void> {
	const loginPath = getRoutePath('login');
	const redirectPath = setRedirectPath();
	if (loginPath) {
		await goto(`${loginPath}?${REDIRECT_TO_SEARCH_PARAM}=${redirectPath}`);
	}
}

export function setRedirectPath(): string {
	// eslint-disable-next-line prefer-const
	let {pathname, searchParams} = get(page).url ?? window.location;
	if (!searchParams) {
		searchParams = new URLSearchParams(window.location.href);
	}
	const loginDef = getRouteDefinition('login');
	const homeDef = getRouteDefinition('home');

	//remove trailing slash from pathname
	if (loginDef?.pathname === pathname.replace(/\/$/, '')) {
		return btoa(homeDef?.pathname || '/');
	} else {
		searchParams.delete(REDIRECT_TO_SEARCH_PARAM);
		searchParams.delete('token');
		const search = searchParams.toString();
		return search.length
			? btoa(`${pathname}?${searchParams.toString()}`)
			: btoa(pathname);
	}
}

export function getRedirectPath(): string | null {
	return getSearchParamValue(REDIRECT_TO_SEARCH_PARAM);
}

export function isPublicRoute(
	navigation: BeforeNavigate | undefined = undefined
): boolean {
	const route = navigation?.to?.route?.id || location.pathname;

	if (route) {
		const routeDef = getRouteDefinition(route);
		return routeDef?.public || false;
	}
	return false;
}

export function isAccessibleRoute(
	navigation: BeforeNavigate | undefined = undefined
): boolean {
	const route = navigation?.to?.url?.pathname || get(page).url?.pathname;

	if (route) {
		const routeDef = getRouteDefinition(route);
		return routeDef?.accessible !== false;
	}
	return false;
}

export function getSearchParamValue(param: string): string | null {
	return get(page).url.searchParams.get(param);
}

export function isPageViewPath(navigation: BeforeNavigate): boolean {
	const routeId = navigation.to?.route.id;
	if (!routeId) {
		return false;
	}
	const pageViews = [
		'contract_view',
		'invoice_view',
		'sale_view',
		'logistics_view'
	];
	const path = resolveRoute(routeId, navigation.to?.params ?? {});
	const routeName = getRouteDefinition(path)?.name;

	return routeName ? pageViews.includes(routeName) : false;
}
